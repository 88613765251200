<template>
  <apexchart
    ref="CrmBarChart"
    :height="500"
    :options="options"
    :series="series"
    v-if="show"
  >
  </apexchart>
</template>
<script>
export default {
  name: "CrmBarChart",
  data: () => ({
    show:false,
    series: [],
    options: {},
  }),
  mounted() {
   this.crm_categories_distribution()
  },
  methods: {
    async crm_categories_distribution() {
      await this.$http
        .post("/crm_categories_distribution")
        .then((res) => {
          console.log("--/crm_categories_distribution", res.data);
          this.series = res.data.series;
          this.options = res.data.options;
          this.show=true
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.apexcharts-tooltip {
  background: white !important;
}
</style>