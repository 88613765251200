<template>
  <div id="crm-data-overview">
    <v-row class="mb-5">
      <v-col cols="12" sm="12">
        <div class="display-1 mb-5">
          CRM Data Overview
          <v-btn
            @click="next()"
            class="float-right btns color-white"
            color="#109CF1"
            elevation="0"
            >Next</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading.crm__data">
      <v-col cols="12" sm="12" class="h-screen">
        <v-progress-circular
          id="sharethis-loading"
          :size="50"
          color="#94D227"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="6" sm="6">
        <div class="text-md font-weight-bold mb-5 capitalize" style="color:#109CF1">
            Top Categories 
        </div>
        <CrmBarChart/>
      </v-col>
      <v-col cols="6" sm="6">
          <div class="text-md font-weight-bold mb-5 capitalize" style="color:#109CF1">
              Top Brands 
          </div>
        <CrmPieChart />
      </v-col>
      <v-col cols="12" sm="12" class="crm-data-table">
        <v-data-table
          show-select
          @input="enterSelect($event)"
          :item-key="item__key"
          v-model="selected"
          :single-select="singleSelect"
          :headers="table__header"
          :items="table__data"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1 mt-10"
          @page-count="pageCount = $event"
        ></v-data-table>
        <v-pagination
          id="crm-table-pagination"
          :total-visible="7"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CrmPieChart from "./Charts/CrmPieChart.vue";
import CrmBarChart from "./Charts/CrmBarChart.vue";
export default {
  components: { CrmPieChart, CrmBarChart },
  name: "CRM-Data-Overview",
  data() {
    return {
      loading: {
        crm__data: true,
      },
      refreshData:false,
      item__key: "id",
      sendButton: true,
      singleSelect: false,
      selected: [],
      page: 1,
      pageCount: 0,
      customers_events: [],
      itemsPerPage: 8,
      table__header: [],
      table__data: [],
    };
  },
  created() {
    // if (this.$store.getters.getStepper.sharethis < 4) {
    //   this.$router.go(-1)
    // }
  },
  mounted() {
    this.get_crm_data_overview();
  },
  methods: {
    async get_crm_data_overview() {
      await this.$http
        .post("/crm_data")
        .then((res) => {
          console.log("--/crm data overview", res.data);
          this.format__data(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next() {
      this.$store.commit("setStepper", { type: "sharethis", val: 2 });
      this.$router.push({ path: "/industry-category" });
    },
    enterSelect(values) {
      if (values.length >= 1) {
        this.sendButton = false;
      } else {
        this.sendButton = true;
      }
    },
    format__data(customers_events) {
      var keys = Object.keys(customers_events[0]);
      keys.forEach((key) => {
        this.table__header.push({ text: key, value: key ,align: 'center'});
      });
      this.item__key = keys[0];
      customers_events.forEach((el) => {
        this.table__data.push(el);
      });
      this.loading = {
        crm__data: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#crm-data-overview::v-deep {
  .crm-data-table {
    position: relative !important;
  }
  .btns * {
    color: white;
  }
  .v-data-table-header {
    background: #009fff42 !important;
  }
  #crm-table-pagination .theme--light.v-pagination .v-pagination__item--active {
    background-color: #ffffff !important;
    border-color: #109cf1 !important;
    color: #109cf1 !important;
  }
  #crm-table-pagination button {
    box-shadow: none !important;
    border: 1px solid #109cf1 !important;
  }
  #crm-table-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"])
    *,
  #crm-table-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"])
    * {
    color: white;
  }
  #crm-table-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"]),
  #crm-table-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"]) {
    background: #109cf1;
  }
  #crm-table-pagination {
    position: absolute !important;
    top: 0;
    right: 0;
  }
  #customers {
    position: relative !important;
  }
}
</style>